<template>
  <!-- 可循环模块 -->
  <div class="common-module">
    <div class="common-module-title">
      <el-form :model="component" :rules="rules" ref="form" @submit.native.prevent>
        <el-form-item prop="title">
          <el-input
            v-model="component.title"
            class="second-title"
            placeholder="情感声量走势" />
        </el-form-item>
      </el-form>
      <span>
        <i class="iconfont icon-yidong" />
        <i class="iconfont icon-shanchu mar-l-10" @click="remove()" />
      </span>
    </div>
    <p class="summary">
      本次的1265条重点信息，敏感信息共620条，占总量中的49.01%。从走势来看，发布高峰为2019-12-22
      18:00:00 至 2019-12-22 19:00:00的时段，共143条。
    </p>
    <img src="@components/brief_v2/custom/images/emotiontrend.png" class="img" />
  </div>
  <!--@end 可循环模块 -->
</template>
<script>
export default {
  name: 'emotionTrend',
  props: {
    component: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      rules: {
        title: [{required: true, message: '请选择填写模块标题', trigger: 'blur'}]
      }
    }
  },
  methods: {
    remove() {
      this.$emit('remove')
    }
  }
}
</script>
