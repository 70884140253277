<template>
  <div class="common-module">
    <div class="common-module-title">
      <el-form :model="component" :rules="rules" ref="form" @submit.native.prevent>
        <el-form-item prop="title">
          <el-input v-model="component.title" class="first-title" placeholder="处置建议" />
        </el-form-item>
      </el-form>
      <span>
        <i class="iconfont icon-yidong" />
        <i class="iconfont icon-shanchu mar-l-10" @click="remove()" />
      </span>
    </div>
    <p class="summary">
      对于舆情信息中具有潜在危害的事件及情况应给予关注并积极处理，防止不良影响产生及扩散。此外，密切关注此前敏感预警事件的发展情况，及时制定有效应对措施。鉴于监测结果中负面舆情时有发生，
      应吸取相关经验教训，做好预防和处理工作。
    </p>
  </div>
</template>
<script>
export default {
  name: 'handle',
  props: {
    component: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      rules: {
        title: [{required: true, message: '请选择填写模块标题', trigger: 'blur'}]
      }
    }
  },
  methods: {
    remove() {
      this.$emit('remove')
    }
  }
}
</script>
