<template>
  <div class="common-module">
    <div class="common-module-title">
      <el-form :model="component" :rules="rules" ref="form" @submit.native.prevent>
        <el-form-item prop="title">
          <el-input v-model="component.title" class="second-title" placeholder="热门关键词" />
        </el-form-item>
      </el-form>
      <span>
        <i class="iconfont icon-yidong" />
        <i class="iconfont icon-shanchu mar-l-10" @click="remove()" />
      </span>
    </div>
    <p class="summary">
      本时段，根据监测系统生成的关键词云图可知，媒体的关注点较之前有所增多，“接种”、“疫苗”等为媒体讨论的焦点话题。
    </p>
    <img src="@components/brief_v2/custom/images/hotword.png" class="img" />
  </div>
</template>
<script>
export default {
  name: 'hotWord',
  props: {
    component: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      rules: {
        title: [{required: true, message: '请选择填写模块标题', trigger: 'blur'}]
      }
    }
  },
  methods: {
    remove() {
      this.$emit('remove')
    }
  }
}
</script>
