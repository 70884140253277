<template>
  <div class="content custom-template">
    <el-card>
      <div class="mod-title mar_0">
        {{ id === undefined ? '创建' : '编辑' }}自定义模版
      </div>
    </el-card>
    <div
      class="mar-t-20 flex space-between"
      style="align-items: stretch;height:calc(100% - 80px)"
    >
      <el-card class="aside">
        <dl>
          <dt>概述组件</dt>
          <dd @click="addComponent('summary')">简报概述</dd>
          <dd @click="addComponent('handle')">处置建议</dd>
          <dt>数据分析</dt>
          <dd @click="addComponent('voicetrend')">
            信息声量走势
          </dd>
          <dd @click="addComponent('emotiontrend')">
            情感声量走势
          </dd>
          <dd @click="addComponent('mediadistrict')">
            媒体来源分布
          </dd>
          <dd @click="addComponent('sensitive')">敏感信息占比</dd>
          <dd @click="addComponent('hotword')">热门关键词</dd>
          <dd @click="addComponent('voicedistrict')">
            地区声量分布
          </dd>
          <dd @click="addComponent('info')">重点信息</dd>
        </dl>
      </el-card>
      <!-- 内容区域 -->
      <el-card class="main custom-template-cont" v-loading="loading.fetch">
        <div class="t-c mar-t-5 border-bottom">
          <h2 class="report-name">舆情报告标题名称</h2>
          <p class="sub-title mar-t-10">副标题</p>
        </div>
        <div id="parent-level">
          <div
            class="common-border tuozhuai"
            v-show="
              components.summary.length < 1 &&
                components.analysis.length < 1 &&
                components.handle.length < 1
            "
          >
            <i class="iconfont icon-tuozhuai"></i>
            <p>点击左侧组件制作简报</p>
          </div>
          <div
            v-for="(component, index) in components.summary"
            :key="`summary-${index}`"
            data-id="summary"
            class="parent"
          >
            <component-summary
              :component="component"
              @remove="confirmRemove('parent', component)"
            />
          </div>
          <!-- 数据分析 -->
          <div
            class="common-border data-analysis parent"
            v-show="components.analysis.length >= 1"
            data-id="analysis"
          >
            <div class="common-module-title mar-b-20 ignore-element">
              <span class="first-title">数据分析</span>
            </div>
            <div id="child-level">
              <div
                v-for="(component, index) in components.analysis"
                :key="index"
                class="child"
                :data-id="component.component"
              >
                <component-voicetrend
                  :component="component"
                  @remove="confirmRemove('child', component)"
                  v-if="component.component === 'voicetrend'"
                />
                <component-emotiontrend
                  :component="component"
                  @remove="confirmRemove('child', component)"
                  v-else-if="component.component === 'emotiontrend'"
                />
                <component-media-district
                  :component="component"
                  @remove="confirmRemove('child', component)"
                  v-else-if="component.component === 'mediadistrict'"
                />
                <component-sensitive
                  :component="component"
                  @remove="confirmRemove('child', component)"
                  v-else-if="component.component === 'sensitive'"
                />
                <component-hotword
                  :component="component"
                  @remove="confirmRemove('child', component)"
                  v-else-if="component.component === 'hotword'"
                />
                <component-voicedistrict
                  :component="component"
                  @remove="confirmRemove('child', component)"
                  v-else-if="component.component === 'voicedistrict'"
                />
                <component-info
                  :component="component"
                  @remove="confirmRemove('child', component)"
                  v-else-if="component.component === 'info'"
                />
              </div>
            </div>
          </div>
          <!--@end 数据分析 -->
          <!-- 处置建议 -->
          <div
            class="common-border overview parent"
            v-for="(component, index) in components.handle"
            :key="`handle-${index}`"
            data-id="handle"
          >
            <component-handle
              :component="component"
              @remove="confirmRemove('parent', component)"
            />
          </div>
          <!--@end 处置建议 -->
          <div class="button-group">
            <el-button
              type="primary round"
              @click="saveBefore()"
              :disabled="components.analysis.length < 1"
              >保存</el-button
            >
            <el-button round @click="toBack()">取消</el-button>
          </div>
        </div>
      </el-card>
      <!--@end 内容区域 -->
    </div>
    <!-- 保存模板 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="dialog.visible"
      class="Dialog_style1 save-tempate"
    >
      <div class="mod-title" slot="title">保存模板</div>
      <div class="cont">
        <el-form :model="dialog" :rules="rules" ref="dialog" label-width="80px">
          <el-form-item label="模版名称" prop="name">
            <el-input
              v-model="dialog.name"
              class="w-240"
              maxlength="10"
              show-word-limit
            />
          </el-form-item>
        </el-form>
      </div>
      <div class="button-group">
        <el-button
          round
          type="primary"
          @click="save()"
          v-loading="loading.save"
          :disabled="loading.save"
          >确定</el-button
        >
        <el-button round @click="dialog.visible = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 删除模块 -->
    <confirm
      ref="confirmDel"
      confirm="确定"
      title="删除模块"
      class="single-information"
      @must="remove"
    />
    <!--@end 删除模块 -->
  </div>
</template>
<script>
import confirm from '@components/common/confirm';
import Sortable from 'sortablejs';
import componentSummary from '@components/brief_v2/custom/components/summary.vue';
import componentVoicetrend from '@components/brief_v2/custom/components/voicetrend.vue';
import componentEmotiontrend from '@components/brief_v2/custom/components/emotiontrend.vue';
import componentMediaDistrict from '@components/brief_v2/custom/components/mediadistrict.vue';
import componentSensitive from '@components/brief_v2/custom/components/sensitive.vue';
import componentHotword from '@components/brief_v2/custom/components/hotword.vue';
import componentVoicedistrict from '@components/brief_v2/custom/components/voicedistrict.vue';
import componentInfo from '@components/brief_v2/custom/components/info.vue';
import componentHandle from '@components/brief_v2/custom/components/handle.vue';
import { store, find } from '@/api/custom_briefing';

const { _ } = window;
export default {
  components: {
    confirm,
    componentSummary,
    componentVoicetrend,
    componentEmotiontrend,
    componentMediaDistrict,
    componentSensitive,
    componentHotword,
    componentVoicedistrict,
    componentInfo,
    componentHandle
  },
  data() {
    return {
      id: undefined,
      title: '舆情报告标题名称',
      subtitle: '',
      dialog: {
        name: '',
        visible: false
      },
      components: {
        summary: [],
        analysis: [],
        handle: []
      },
      sortData: {
        parent: [],
        child: []
      },
      rules: {
        name: [
          { required: true, message: '请填写自定义模板名称', trigger: 'blur' }
        ]
      },
      loading: {
        save: false,
        fetch: false
      },
      data: undefined // 保存结果
    };
  },
  mounted() {
    this.initEvent();
    if (this.$route.query.hasOwnProperty('id')) {
      this.fetchDetail(this.$route.query.id);
    }
  },
  methods: {
    fetchDetail(id) {
      this.loading.fetch = true;
      find({ id }).then(res => {
        if (res.data.state) {
          const { components, name, id } = res.data.data;
          this.id = id;
          this.loading.fetch = false;
          this.dialog.name = name;
          const keys = _.keys(components);
          _.forEach(keys, key => {
            switch (key) {
              case 'summary':
                if (components.summary) {
                  this.addComponent(key, components.summary.title);
                }
                break;
              case 'handle':
                if (components.handle) {
                  this.addComponent(key, components.handle.title);
                }
                break;
              case 'analysis':
                _.forEach(components.analysis, component => {
                  this.addComponent(component.component, component.title);
                });
                break;
            }
          });
        }
      });
    },
    save() {
      const { dialog, data, id } = this;

      this.$refs['dialog'].validate(valid => {
        if (valid) {
          const args = {
            name: dialog.name,
            components: JSON.stringify(data)
          };

          if (id !== undefined) {
            args.id = id;
          }

          this.loading.save = true;
          store(args)
            .then(res => {
              if (res.data.state) {
                this.$message.success('保存成功');
                this.dialog.visible = false;

                this.$router.push({
                  name: 'brief',
                  params: { activeName: 'template' }
                });
              } else {
                this.$message.error(res.data.error);
              }
              this.loading.save = false;
            })
            .catch(e => {
              window.console.error(e);
            });
        }
      });
    },
    saveBefore() {
      const data = this.verify();
      if (!data) {
        return false;
      }

      this.data = data;
      this.dialog.visible = true;
      this.$nextTick(() => {
        this.$refs['dialog'].resetFields();
      });
    },
    toBack() {
      this.$router.back(-1);
    },
    verify() {
      const { components, sortData } = this;

      const summary = _.head(components.summary);
      if (summary !== undefined) {
        if (summary.title.length < 1) {
          // this.$message.warning('简报概述模块标题不能为空')
          return false;
        }
      }

      if (components.analysis.length < 1) {
        return false;
      }

      for (let i = 0; i < components.analysis.length; i++) {
        if (components.analysis[i].title.length < 1) {
          return false;
        }
      }

      const handle = _.head(components.handle);
      if (handle !== undefined) {
        if (handle.title.length < 1) {
          // this.$message.warning('简报概述模块标题不能为空')
          return false;
        }
      }

      const data = {};
      _(sortData.parent).forEach(component => {
        const first = _.head(components[component]);
        data[component] = first === undefined ? '' : first;
      });

      data.analysis = [];
      _(sortData.child).forEach(componentName => {
        const component = _.find(components.analysis, {
          component: componentName
        });
        if (component !== undefined) {
          data.analysis.push(component);
        }
      });

      return data;
    },
    addComponent(component, title = undefined) {
      if (this.componentExists(component)) {
        return false;
      }
      switch (component) {
        case 'summary':
          title = title === undefined ? '简报概述' : title;
          this.components.summary = [{ title, component }];
          this.handleSort('parent');
          break;
        case 'voicetrend':
          title = title === undefined ? '信息声量走势' : title;
          this.components.analysis = this.components.analysis.concat({
            title,
            component
          });
          this.handleSort('child');
          break;
        case 'emotiontrend':
          title = title === undefined ? '情感声量走势' : title;
          this.components.analysis = this.components.analysis.concat({
            title,
            component
          });
          this.handleSort('child');
          break;
        case 'mediadistrict':
          title = title === undefined ? '媒体来源分布' : title;
          this.components.analysis = this.components.analysis.concat({
            title,
            component
          });
          this.handleSort('child');
          break;
        case 'sensitive':
          title = title === undefined ? '敏感信息占比' : title;
          this.components.analysis = this.components.analysis.concat({
            title,
            component
          });
          this.handleSort('child');
          break;
        case 'hotword':
          title = title === undefined ? '热门关键词' : title;
          this.components.analysis = this.components.analysis.concat({
            title,
            component
          });
          this.handleSort('child');
          break;
        case 'voicedistrict':
          title = title === undefined ? '地区声量分布' : title;
          this.components.analysis = this.components.analysis.concat({
            title,
            component
          });
          this.handleSort('child');
          break;
        case 'info':
          title = title === undefined ? '重点信息' : title;
          this.components.analysis = this.components.analysis.concat({
            title,
            component
          });
          this.handleSort('child');
          break;
        case 'handle':
          title = title === undefined ? '处置建议' : title;
          this.components.handle = [{ title, component }];
          this.handleSort('parent');
          break;
      }
    },
    removeParent(index) {
      if (['summary', 'handle'].indexOf(index) === -1) {
        return false;
      }

      this.components[index] = [];
      this.handleSort('parent');
    },
    removeChild(index) {
      this.components.analysis = this.components.analysis.filter(component => {
        return component.component !== index;
      });

      this.handleSort('child');
    },
    componentExists(component) {
      const { components } = this;
      let exists = undefined;
      switch (component) {
        case 'summary':
          exists = components.summary.length >= 1;
          break;
        case 'handle':
          exists = components.handle.length >= 1;
          break;
        default:
          exists = _.find(this.components.analysis, { component });
      }

      return Boolean(exists);
    },
    initEvent() {
      this.$nextTick(() => {
        Sortable.create(document.getElementById('parent-level'), {
          filter: '.ignore-element',
          animation: 1000,
          onEnd: () => {
            this.handleSort('parent');
          }
        });
        Sortable.create(document.getElementById('child-level'), {
          filter: '.ignore-element',
          animation: 1000,
          onEnd: () => {
            this.handleSort('child');
          }
        });
      });
    },
    handleSort(type) {
      if (['parent', 'child'].indexOf(type) === -1) {
        return false;
      }

      this.$nextTick(() => {
        let elements;
        if (type === 'parent') {
          elements = window.jQuery('#parent-level .parent');
        } else {
          elements = window.jQuery('#child-level .child');
        }

        const data = [];
        for (let i = 0; i < elements.length; i++) {
          const index = window.jQuery(elements[i]).data('id');
          data.push(index);
        }
        this.sortData[type] = data;
      });
    },
    remove(data) {
      const { type, component } = data;
      switch (type) {
        case 'parent':
          this.removeParent(component);
          break;
        case 'child':
          this.removeChild(component);
          break;
      }
    },
    confirmRemove(type, component) {
      const data = {
        type,
        component: component.component
      };
      this.remove(data);

      // const textBox = [
      //   {
      //     type: 'error',
      //     text: `确定删除${component.title}模块吗？`
      //   }
      // ];
      // this.$refs.confirmDel.show(textBox, data);
    }
  }
};
</script>
<style scoped>
.custom-template {
  height: calc(100% - 120px);
}
.custom-template .mod-title.mar_0 {
  margin-bottom: 0;
}
.custom-template .aside {
  flex: 1;
  margin-right: 20px;
}
.custom-template .aside >>> .el-card__body {
  padding-left: 35px;
}
.custom-template .aside dt {
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  cursor: pointer;
}
.custom-template .aside dd {
  font-size: 14px;
  line-height: 30px;
  padding-left: 20px;
  cursor: pointer;
}
.main {
  width: 1180px;
}
/* 报告内容 */
.custom-template-cont >>> .el-card__body {
  padding: 20px 30px;
  height: calc(100% - 40px);
  overflow: auto;
}
.report-name {
  line-height: 40px;
  font-size: 20px;
  font-weight: 550;
  color: #c4221c;
}
.custom-template .sub-title {
  line-height: 30px;
  border-radius: 2px;
  color: #bcbcc1;
}
/* 舆情概述 */
.custom-template-cont .border-bottom {
  border-bottom: 2px #c4221c solid;
  padding-bottom: 25px;
  margin-bottom: 30px;
}
.custom-template .overview {
  padding: 15px;
}
.custom-template .mg-text {
  color: #fc5d73;
}
.save-tempate >>> .el-form-item__label {
  font-weight: 600;
}
.custom-template-cont .tuozhuai {
  text-align: center;
  font-size: 14px;
  line-height: 40px;
  padding: 100px 0;
}
.custom-template-cont .tuozhuai .iconfont {
  font-size: 40px;
}
</style>
