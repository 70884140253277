<template>
  <!-- 舆情概述 -->
  <div class="common-border overview">
    <div class="common-module">
      <div class="common-module-title ">
        <el-form :model="component" :rules="rules" ref="form" @submit.native.prevent>
          <el-form-item prop="title">
            <el-input v-model="component.title" class="first-title" placeholder="舆情概述" />
          </el-form-item>
        </el-form>
        <span>
          <i class="iconfont icon-yidong" />
          <i class="iconfont icon-shanchu mar-l-10" @click="remove()" />
        </span>
      </div>
      <p class="summary">
        本次简报涵盖了 2021-04-12 00:00:00 至 2021-04-18 23:59:59
        期各大网站及社交平台共监测到舆情信息XXX条。其中，正面舆情信息有XX篇，占比XX%；敏感舆情信息有XX篇，占比XX%。本监测期内舆情态势保持健康/严峻状态。
      </p>
    </div>
  </div>
  <!-- @end 舆情概述-->
</template>
<script>
export default {
  name: 'componentSummary',
  props: {
    component: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      rules: {
        title: [{required: true, message: '请选择填写模块标题', trigger: 'blur'}]
      }
    }
  },
  methods: {
    remove() {
      this.$emit('remove')
    }
  }
};
</script>
