import { render, staticRenderFns } from "./voicedistrict.vue?vue&type=template&id=bbb63f90&"
import script from "./voicedistrict.vue?vue&type=script&lang=js&"
export * from "./voicedistrict.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports