<template>
  <!-- 可循环模块：重点信息 -->
  <div class="common-module info">
    <div class="common-module-title">
      <el-form :model="component" :rules="rules" ref="form" @submit.native.prevent>
        <el-form-item prop="title">
          <el-input v-model="component.title" class="second-title" placeholder="重点信息" />
        </el-form-item>
      </el-form>
      <span>
        <i class="iconfont icon-yidong" />
        <i class="iconfont icon-shanchu mar-l-10" @click="remove()" />
      </span>
    </div>
    <el-table
      :data="tableData"
      class="w-100 mar-t-10"
      border
      :header-cell-style="{ background: '#F3F5FC' }">
      <el-table-column label="标题" align="center">
        <template slot-scope="scope">
          <div class="table-title" :title="scope.row.title">
            {{ scope.row.title }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="属性" align="center" width="80">
        <template>
          <span class="mg-text">敏感</span>
        </template>
      </el-table-column>
      <el-table-column width="120" prop="source" label="来源" align="center">
      </el-table-column>
      <el-table-column width="160" prop="time" label="日期" align="center">
      </el-table-column>
      <el-table-column
        width="110"
        prop="number"
        label="相似文章数"
        align="center">
      </el-table-column>
    </el-table>
  </div>
  <!--@end 可循环模块：重点信息 -->
</template>
<script>
export default {
  name: 'info',
  props: {
    component: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      rules: {
        title: [{required: true, message: '请选择填写模块标题', trigger: 'blur'}]
      },
      tableData: []
    }
  },
  methods: {
    remove() {
      this.$emit('remove')
    }
  }
}
</script>
<style scoped>
.info .table-title {
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  width: calc(100% - 20px);
}
.info >>> .cell {
  font-size: 14px;
}
.info >>> .has-gutter .cell {
  font-weight: 600;
}
</style>
